import React from 'react';
import { ResizableBox as ReactResizableBox } from 'react-resizable';

import 'react-resizable/css/styles.css';

export default function ResizableBox({
	children,
	width = 1000,
	height = 550,
	resizable = true,
	className = ''
}) {
	return (
		<div className="ml-[20]">
			<div className={`inline-block w-auto  p-2  ${className}`}>
				{resizable ? (
					<ReactResizableBox width={width} height={height}>
						<div className={`${className} w-full h-full`}>{children}</div>
					</ReactResizableBox>
				) : (
					<div
						style={{
							width: `${width}px`,
							height: `${height}px`
						}}
						className={`${className} w-[${width}] h-[${height}]`}
					>
						{children}
					</div>
				)}
			</div>
		</div>
	);
}
