import React, { useEffect, useState } from 'react';
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import ResizableBox from './ResizableBox';
import Table from './components/Table';
import { validateHeaderValue } from 'http';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);
type Load = {
	labels: string[];
	datasets: {
		label: string;
		data: number[];
		borderColor: string;
		backgroundColor: string;
	}[];
	metadata: any;
};

type Domain = {
	rank: string;
	rankChange: string;
	domain: string;
	Category: string;
};
const WOKER_URL = 'https://workers.oluwapelps.workers.dev';

export default function App() {
	const [val, setVal] = useState<Load>({
		labels: [],
		datasets: [],
		metadata: undefined
	});
	const [domains, setDomains] = useState<Domain[]>();
	const [attacks, setAttacks] = useState<Load>({
		labels: [],
		datasets: [],
		metadata: undefined
	});

	const [active, setActive] = useState('LOAD');
	useEffect(() => {
		async function getLoad() {
			setGraphName('HTTP vs Total Traffic');
			if (val.labels.length === 0) {
				const response = await fetch(`${WOKER_URL}/traffic-change`);
				const data = await response.json();
				console.log(data);

				setVal({
					labels: Object.keys(data.data.total).map((val) => {
						return new Date(data.data.total[val].timestamp).toLocaleString([], {
							month: 'short',
							day: 'numeric',
							hour: '2-digit',
							minute: '2-digit'
						});
					}),
					datasets: [
						{
							label: 'Total',
							data: Object.keys(data.data.total).map((val) => {
								return data.data.total[val].value;
							}),
							borderColor: 'rgb(255, 99, 132)',
							backgroundColor: 'rgba(255, 99, 132, 0.5)'
						},
						{
							label: 'Http',
							data: Object.keys(data.data.http).map((val) => {
								return data.data.http[val].value;
							}),
							borderColor: 'rgb(53, 162, 235)',
							backgroundColor: 'rgba(53, 162, 235, 0.5)'
						}
					],
					metadata: data.meta
				});
			}
		}
		async function getAttacks() {
			setGraphName('Layer 3 DDoS attacks');
			if (attacks.labels.length === 0) {
				const response = await fetch(`${WOKER_URL}/attack-layer3`);
				const data = await response.json();

				setAttacks({
					labels: Object.keys(data.data).map((val) => {
						return new Date(data.data[val].timestamp).toLocaleString([], {
							month: 'short',
							day: 'numeric',
							hour: '2-digit',
							minute: '2-digit'
						});
					}),
					datasets: [
						{
							label: 'Attacks',
							data: Object.keys(data.data).map((val) => {
								return data.data[val].value;
							}),
							borderColor: 'rgb(255, 99, 132)',
							backgroundColor: 'rgba(255, 99, 132, 0.5)'
						}
					],
					metadata: data.meta
				});
			}
		}

		async function getDomains() {
			if (!domains) {
				const response = await fetch(`${WOKER_URL}/popular-domains`);
				const data = await response.json();
				console.log(data);
				setDomains(data.rankingEntries);
			}
		}
		if (active === 'LOAD') {
			getLoad();
		} else if (active === 'DOMAINS') {
			getDomains();
		} else if (active === 'ATTACKS') {
			getAttacks();
		}
	}, [active]);
	const [graphName, setGraphName] = useState('HTTP vs Total Traffic');
	const options: any = {
		responsive: true,
		plugins: {
			legend: {
				position: 'top' as const
			},
			title: {
				display: true,
				text: graphName
			}
		},
		scales: {
			x: {
				ticks: {
					maxTicksLimit: 20
				}
			},
			y: {
				beginAtZero: false
			}
		}
	};

	return (
		<div className="flex justify-center flex-col items-center gap-3 p-5">
			<div className="tabs tabs-boxed">
				<button
					className={`tab ${active === 'LOAD' && 'tab-active'}`}
					onClick={(e) => setActive('LOAD')}
				>
					Load
				</button>
				<button
					className={`tab ${active === 'DOMAINS' && 'tab-active'}`}
					onClick={(e) => setActive('DOMAINS')}
				>
					Top Domains
				</button>
				<button
					className={`tab ${active === 'ATTACKS' && 'tab-active'}`}
					onClick={(e) => setActive('ATTACKS')}
				>
					Attacks
				</button>
			</div>
			{active === 'LOAD' && (
				<div
					className="tooltip tooltip-top tooltip-secondary min-h-full w-3/4"
					data-tip="This chart shows the total and HTTP-only traffic on the Cloudflare network from 25th July to 24th August "
				>
					<ResizableBox>
						<Line options={options} data={val} />
						{val.metadata && (
							<div className="text-2xl flex flex-col gap-3 items-start">
								<div>
									<span className="font-bold  text-primary">Range:</span>{' '}
									{new Date(val.metadata.dateRange.startTime).toLocaleString([], {
										month: 'short',
										day: 'numeric',
										hour: '2-digit',
										minute: '2-digit'
									})}{' '}
									to{' '}
									{new Date(val.metadata.dateRange.endTime).toLocaleString([], {
										month: 'short',
										day: 'numeric',
										hour: '2-digit',
										minute: '2-digit'
									})}
								</div>
								<div>
									<span className="font-bold  text-primary">Last Updated:</span>{' '}
									{new Date(val.metadata.lastUpdated.replace(/\n/g, '')).toLocaleString([], {
										month: 'short',
										day: 'numeric',
										hour: '2-digit',
										minute: '2-digit'
									})}
								</div>
								<div>
									<span className="font-bold text-primary">Confidence Level:</span>{' '}
									{val.metadata.confidenceInfo.level}
								</div>

								{Object.keys(val.metadata.confidenceInfo.annotations).map((key, idx) => (
									<div className="text-sm text-left" key={idx}>
										On{' '}
										{val.metadata &&
											new Date(
												val.metadata.confidenceInfo.annotations[key].startDate
											).toLocaleString([], {
												month: 'short',
												day: 'numeric'
											})}{' '}
										{val.metadata.confidenceInfo.annotations[key].description}
									</div>
								))}
							</div>
						)}
					</ResizableBox>
				</div>
			)}
			{active === 'DOMAINS' && (
				<div
					className="tooltip tooltip-top tooltip-secondary min-h-full w-2/3"
					data-tip="This table shows the most trafficked domains and their rank change over the last 30 days"
				>
					<Table domains={domains} />
				</div>
			)}
			{active === 'ATTACKS' && (
				<div
					className="tooltip tooltip-top tooltip-secondary min-h-full w-2/3"
					data-tip="This chart shows the percentage of Layer 3 DDoS Attack from 25th July to 24th August"
				>
					<ResizableBox>
						<Line options={options} data={attacks} />
						{attacks.metadata && (
							<div className="text-2xl flex flex-col gap-3 items-start">
								<div>
									<span className="font-bold  text-primary">Range:</span>{' '}
									{new Date(attacks.metadata.dateRange.startTime).toLocaleString([], {
										month: 'short',
										day: 'numeric',
										hour: '2-digit',
										minute: '2-digit'
									})}{' '}
									to{' '}
									{new Date(attacks.metadata.dateRange.endTime).toLocaleString([], {
										month: 'short',
										day: 'numeric',
										hour: '2-digit',
										minute: '2-digit'
									})}
								</div>
								<div>
									<span className="font-bold  text-primary">Last Updated:</span>{' '}
									{new Date(attacks.metadata.lastUpdated.replace(/\n/g, '')).toLocaleString([], {
										month: 'short',
										day: 'numeric',
										hour: '2-digit',
										minute: '2-digit'
									})}
								</div>
							</div>
						)}
					</ResizableBox>
				</div>
			)}
		</div>
	);
}
