import React from 'react';
type Domain = {
	rank: string;
	rankChange: string;
	domain: string;
	Category: string;
};
const Table = ({ domains }: { domains: Domain[] | undefined }) => {
	return (
		<div className="overflow-x-auto">
			<table className="table w-full">
				<thead>
					<tr>
						<th>Rank</th>
						<th>Domain</th>
						<th>Category</th>
					</tr>
				</thead>
				<tbody>
					{domains?.map((domain, idx) => (
						<tr key={idx}>
							<th>{`${domain.rank} ${
								parseInt(domain.rankChange) === 1
									? `⬆️(Prev: ${parseInt(domain.rank) + parseInt(domain.rankChange)})`
									: parseInt(domain.rankChange) === -1
									? `⬇️(Prev: ${parseInt(domain.rank) + parseInt(domain.rankChange)})`
									: ''
							}`}</th>
							<td>{domain.domain}</td>
							<td>{domain.Category}</td>
						</tr>
					))}
				</tbody>
			</table>
		</div>
	);
};

export default Table;
